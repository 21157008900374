// extracted by mini-css-extract-plugin
export var emailContent = "email-module--emailContent--nGj-p";
export var formControl = "email-module--formControl--eSGny";
export var formGroup = "email-module--formGroup--Zd7pS";
export var formStyle = "email-module--formStyle--jGnQs";
export var formWrap = "email-module--formWrap--iCP1P";
export var formWrap1 = "email-module--formWrap1--rIzj+";
export var inputFields = "email-module--inputFields--eqs4c";
export var thanks = "email-module--thanks--ZpQvl";
export var thanksimage = "email-module--thanksimage--Rqhoc";
export var thankyou = "email-module--thankyou--nrrXX";