import * as React from "react"
import Layout from "../components/layout"
import Email from "../components/Email"

const EmailPage = () => (
  <Layout>
    <Email />
  </Layout>
)

export default EmailPage